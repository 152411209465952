<template>
    <div class=" has-text-weight-semibold">
        <div class="is-large has-background-gray has-text-centered">

            <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
                <div class="columns is-mobile">
                    <div class="column is-size-6 has-text-centered">CREDIT/DEBIT CHARGES NET DEPOSIT</div>
                    <div class="level-right has-text-centered">
                        <b-button title="Export to Excel" type="is-primary" outlined class="is-small is-hidden-mobile" @click="jsonAsXlsx">
                            <!-- <img src="/images/spreadsheet.png" width="20" height="20" alt="" class="is-rounded"> -->
                            <i class="fas fa-file-excel"></i>
                        </b-button>
                        <!-- <b-button class="is-dark is-small is-hidden-mobile" @click="togglePDFDialog">Print</b-button> -->
                        <b-button  title="Print" type="is-dark" outlined class="is-small is-hidden-mobile" @click="togglePDFDialog">
                            <i class="fas fa-print"></i>
                        </b-button>
<!-- 
                        &nbsp;&nbsp;
                        <b-button class="is-small has-background-success-light is-hidden-mobile" @click="jsonAsXlsx">
                            <img src="/images/spreadsheet.png" width="20" height="20" alt="" class="is-rounded">
                        </b-button> -->
                    </div>                    
                </div>
                                
            </div>
            <div class="columns">
                <div class="column is-4 has-text-left">
                    <b-field>
                        <date-range-selector
                            :dateRange="salesDateRange"
                            @set-date-range="setDateRange"
                            >
                        </date-range-selector>                    
                    </b-field>                                      
                </div>
            </div> 
        </div>
        <br>
        <div class="panel-heading has-text-weight-bold">
            <div class="columns">

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Sales</p>
                    <p class="is-size-6"> {{ totalNetSales | formatCurrency }}</p>
                    </div>
                </div>   
                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Fuel Sales</p>
                    <p class="is-size-6"> {{ totalFuelSales | formatCurrency }}</p>
                    </div>
                </div>                   

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Credit & Debit Card</p>
                    <p class="is-size-6"> {{ totalCreditDebitCharges| formatCurrency }}</p>
                    </div>
                </div>   

                <div class="column has-text-centered">
                    <div>
                    <p class="is-size-7">Total Deposit</p>
                    <p class="is-size-6"> {{ totalFuelSales - totalCreditDebitCharges | formatCurrency }}</p>
                    </div>
                </div>   
            </div>
        </div>
        <br>
        <b-table v-if="dailySales"
            class="has-text-weight-semibold"
            :visible="!isLoading"
            :data='dailySales'
            striped
            :scrollable="isScrollable"
            :paginated="isPaginated"
            :per-page="perPage"
            paginationPosition="both"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort="date"
            default-sort-direction="asc"
            :show-detail-icon="showDetailIcon"
            >

            <template>
           
                <b-table-column class="is-2" field="date" label="Date" v-slot="props" sortable sorticon>
                    {{ props.row.date.substr(0,10) | formatDate }}
                </b-table-column>
            
                <b-table-column numeric field="netSales" label="Net Sales" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.netSales | formatCurrency}}
                </b-table-column>

                <b-table-column numeric field="fuelSales" label="Fuel Sales" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.fuelSales | formatCurrency}}
                </b-table-column>

                <b-table-column numeric field="fuelVolume" label="Fuel Volume" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.fuelVolume | formatFuelVolumeSimple}} 
                </b-table-column>

                <b-table-column numeric field="fuelDelivered" label="Fuel Delivered" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.fuelDelivered | formatFuelVolumeSimple}} 
                </b-table-column>
              

                <b-table-column numeric field="creditAmount" label="Credit Card Amount" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.creditAmount  | formatCurrency }}
                </b-table-column>

                <b-table-column numeric field="debitAmount" label="Debit Card Amount" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.debitAmount  | formatCurrency }}
                </b-table-column>    

                <b-table-column numeric field="totalAmount" label="Credit & Debit Total" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.creditDebitAmount | formatCurrency }}
                </b-table-column>               

                <b-table-column numeric field="netDeposit" label="Net Deposit" align="right" v-slot="props" sortable sorticon>
                    {{ props.row.netDeposit | formatCurrency }}
                </b-table-column>
            </template>

            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>
        </b-table>
        <credit-card-net-deposit-PDF-report
            ref="pdfReport"
            :isShowPDFDialog="isShowPDFDialog"
            :filteredRecords="dailySales"
            :dateRange="salesDateRange"
            @close-pdf-dialog="togglePDFDialog"
        >

        </credit-card-net-deposit-PDF-report>
       
    </div>
</template>

<script>

import UserStore from "../../../../store/UserStore"
import DataLoading from '../../../../components/app/DataLoading'
import axios from 'axios'
import DateRangeSelector from "../../../../components/app/DateRangeSelector"
import moment from 'moment'
import CreditCardNetDepositPDFReport from "./CreditCardNetDepositPDFReport"
import jsonAsXLSX from 'json-as-xlsx'

export default {

    components: {
        DataLoading,
        DateRangeSelector,
        CreditCardNetDepositPDFReport
    },

    metaInfo: {
        title: 'Credit/Debit Charges Net Deposit'
    },

    data() {
        return {
            users: [],
            showNumberControls: false,
            departmentMappingTotals: [],
            dailySales: [],
            delivery: [],
            dailyDeliveryGroup: [],
            fuelProducts: [],
            deptSales: [],
            totalSales: {},
            totalNetSales: 0,
            totalFuelSales: 0,
            totalCreditDebitCharges: 0,
            isScrollable: true,
            isPaginated: false,
            showDetailIcon: true,
            perPage: 40,
            isLoading: true,
            isFullPage: true,
            isShowPDFDialog: false,
            storeId: 0,
            salesDateRange: [new Date(), new Date()],
            mapTotal: 0.00,
            ccVendor: {}, 
            ccVendorRecord: {
                id: 0,
                name: ""
            },
            salesFields: [
                    'date',
                    'creditAmount',
                    'debitAmount',
                    'lineCardAmount',
                    'netSales',
                    'totalTaxes',
                    'totalSalesTaxes',
                    'totalRefundTaxes',
                    'cancelRefund',
                    'cashRefundAmount',
                    'voidLineAmount',
                    'totalPaymentOut',
                    'payOut',
                    'payIn',
                    'merchSales',
                    'fuelSales',
                    'cashAmount',
                    'creditCardFees',
                    'creditRefundAmount'
                    ],
            chartOptions: {
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true
                                }
                            }],
                    },
                    responsive: true,
                    maintainAspectRatio: false
                },        
        } 

    },

    watch: {
        salesDateRange: function() {
            if (UserStore.getters.getActiveStore.role != 'STAFF') {
                this.fetchStoreDelivery()
                this.fetchStoreSales()
            } else {
                this.isLoading = false
            }
        }

    },

    methods: {

        fetchStoreDelivery() {
            
            if (this.user.activeStore) {

                var startDate = this.salesDateRange[0].toISOString().split('T')[0] 
                var endDate = this.salesDateRange[1].toISOString().split('T')[0]
                
                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                            + '/tanks/deliveries' + "?start_date="+startDate+"&end_date="+endDate
                            // + '/tanks/deliveries?page=1&size=50'

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.delivery = response.data.data
                        
                        this.delivery.forEach ((item) => {
                            item.deliveryDate = new Date(item.startDate).toISOString().split('T')[0]
                        })

                        this.groupedDeliveryByDate()

                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }

        },

        fetchVendor() {
            if (this.ccVendor.value > 0) {
                const url = process.env.VUE_APP_API_ROOT_URL + 'vendors/' + this.ccVendor.value
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        
                        this.ccVendorRecord = response.data.data

                    })
                    .catch( (err) => {
                        this.isLoading = false
                        if (err.response) {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                            if (err.response.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }                            
                        } else {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err)

                            if (err.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }
                        }
                        
                        this.isLoading = false

                    })                
            }
        },

        fetchStoreSales() {
            if (this.user.activeStore) {

                this.totalSales.netSales = 0.00
                this.dailySales = []
                this.isLoading = true
                this.dataSales = []

                var startDate = this.salesDateRange[0].toISOString().split('T')[0]
                var endDate = this.salesDateRange[1].toISOString().split('T')[0]

                const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                    + '/sales?size=50'  + "&fromDate="+startDate+"&toDate="+endDate

                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        
                        this.loadDailySales(Object.entries(response.data.data))

                    })
                    .catch( (err) => {
                        this.isLoading = false
                        if (err.response) {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                            if (err.response.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }                            
                        } else {
                            if (process.env.VUE_APP_DEBUG == 'true') console.log(err)

                            if (err.status === 403) {
                                alert('Session Expired. Please log in again!')
                                UserStore.commit('userLogout')
                            }
                        }
                        
                        this.isLoading = false

                    })
            }
            
        },

        groupedDeliveryByDate() {

            this.dailyDeliveryGroup = Object.values(this.delivery.reduce((dateGroup,{deliveryDate, ...props}) => {
                if(!dateGroup[deliveryDate]) {
                    dateGroup[deliveryDate] = Object.assign({}, {dateGroup: props, deliveries : [props]});
                    dateGroup[deliveryDate].deliveredFuel = 0.00
                } else {
                    dateGroup[deliveryDate].deliveries.push(props);
                }
                dateGroup[deliveryDate].deliveredFuel += props.endingFuelVolume - props.startingFuelVolume
                dateGroup[deliveryDate].deliveryDate = deliveryDate
                if (props.startingWaterVolume > 0) {
                    dateGroup[deliveryDate].startingWaterVolume = props.startingWaterVolume
                }                
                if (props.endingWaterVolume > 0) {
                    dateGroup[deliveryDate].endingWaterVolume = props.endingWaterVolume
                }
                
                return dateGroup;
            },{}));

        },              


        loadDailySales(items) {
            
            this.isLoading = true

            var item

            this.totalSales = []

            this.dailySales = []

            for (item of items) {

                if (! item[1].netSalesDTO.netSales) {
                    continue
                }

                let itemObj = Object.keys(item[1].netSalesDTO)
                    .filter(key => this.salesFields.indexOf(key) >= 0)
                    .reduce((itemObj, key) => Object.assign(itemObj, { [key]: item[1].netSalesDTO[key] }), {})

                itemObj.otherPayment = itemObj.netSales - itemObj.creditAmount - itemObj.debitAmount - itemObj.lineCardAmount - itemObj.cashAmount + itemObj.cancelRefund

                if (itemObj.creditAmount == null) {
                    itemObj.creditAmount = 0
                }
                if (itemObj.debitAmount == null) {
                    itemObj.debitAmount = 0
                }
                
                if (item[1].fuelSalesDTO) {
                    itemObj.fuelVolume = this.totalFuelSalesVolume(item[1].fuelSalesDTO)
                }
                
                itemObj.creditDebitAmount = itemObj.creditAmount + itemObj.debitAmount
                itemObj.netDeposit = itemObj.fuelSales - itemObj.creditDebitAmount
                itemObj.salesId = item[1].netSalesDTO.id

                
                itemObj.fuelDelivered = 0
                this.dailyDeliveryGroup.forEach(delivery => {
                    if (delivery.deliveryDate == itemObj.date) {
                        itemObj.fuelDelivered = delivery.deliveredFuel
                    }
                })

                this.dailySales.push(itemObj)
            }

            this.totalNetSales = this.dailySales.reduce((accum,item) => accum + parseFloat(item.netSales), 0)
            this.totalFuelSales = this.dailySales.reduce((accum,item) => accum + parseFloat(item.fuelSales), 0)
            this.totalCreditDebitCharges = this.dailySales.reduce((accum,item) => accum + parseFloat(item.creditDebitAmount), 0)

            this.dailySales.sort(function (a, b) {
                return a.date.localeCompare(b.date)
            });

            if (this.dailySales.length > this.perPage) {
                this.isPaginated = true
            } else {
                this.isPaginated = false
            }

            this.isLoading = false

        },

        applyFilter() {
            this.fetchStoreDelivery()
            this.fetchStoreSales()
        },

        setDateRange(dateRange) {
            this.salesDateRange = [dateRange[0], dateRange[1]]
        },
        
        disableEdit(row) {
            return row.creditCardFees == row.creditCardFeesUpdated || this.ccVendorRecord.name == ""
        },
        
        debitCreditTotal(row) {
            return row.creditAmount + row.debitAmount -  row.creditRefundAmount
        },

        netDeposit(row) {
            return row.fuelSales - row.creditAmount - row.debitAmount          
        },
        
        formatDate(date) {
            return moment(date).format('MM/DD/YYYY')
        },

        formatCurrency(num) {
            return num = new Intl.NumberFormat(`en-US`, {
                    currency: `USD`,
                    style: 'currency',
                }).format(num);

        },   

        togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
            if (this.isShowPDFDialog) {
                this.$refs.pdfReport.loadData()
            }
        },

        beforeLeavingPage(event) {
            
            if (this.unsavedUpdates.length > 0) {          
                event.preventDefault()
                event.returnValue = ""
            }
    
        }, 

        totalFuelSalesVolume(fuelDTO) {
            return fuelDTO.reduce((accum,item) => accum + parseFloat(item.volume), 0)
        }, 
        
        jsonAsXlsx() {
            var jsonAsXlsxData = [
                {
                    sheet: "Net Deposit",
                    columns: [
                    { label: "Date", value: "date" },
                    { label: "Net Sales", value: "netSales", format: "$#,##0.00"}, 
                    { label: "Fuel Sales", value: "fuelSales", format: "$#,##0.00"},
                    { label: "Fuel Volume", value: "fuelVolume", format: "#,##0.000"},
                    { label: "Fuel Delivered", value: "fuelDelivered", format: "#,##0.000"},
                    { label: "Credit Card", value: "creditAmount", format: "$#,##0.00"},
                    { label: "Debit Card", value: "debitAmount", format: "$#,##0.00"},
                    { label: "Credit & Debit", value: "creditDebitAmount", format: "$#,##0.00"},
                    { label: "Net Deposit", value: "netDeposit", format: "$#,##0.00"},
                    ],
                    content: this.dailySales,
                }]
                
            let settings = {
                fileName: "VEECLi-NetDeposit-" + moment().format('YYYY-MM-DD-HH-mm-ss'), // Name of the resulting spreadsheet
                extraLength: 1, // A bigger number means that columns will be wider
                writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
                writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
                RTL: false, // Display the columns from right-to-left (the default value is false)
            }

            jsonAsXLSX(jsonAsXlsxData, settings) // Will download the excel file

        },  
    },

    computed: {
        // getUser() {
        //     return UserStore.getters.getUser
        // },

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

        columnTotals() {
            return [
                100, 200, 300, 400
            ];
        },

        totalCreditCharges() {
            return this.dailySales.reduce((accum,item) => accum + parseFloat(item.creditAmount), 0)
        },

        totalDebitCharges() {
            return this.dailySales.reduce((accum,item) => accum + parseFloat(item.debitAmount), 0)
        },
        
        totalCharges() {
            return this.totalCreditCharges + this.totalDebitCharges
        },

        totalFees() {
            return this.dailySales.reduce((accum,item) => accum + parseFloat(item.creditCardFees), 0)
        },

        notCollected() {
            var collectedRecs = this.dailySales.filter(item => (item.creditCardFees == 0)) 
            return collectedRecs.reduce((accum,item) => accum + parseFloat(item.creditAmount) + parseFloat(item.debitAmount), 0)
        },

        netCollected() {
            return this.totalCreditCharges + this.totalDebitCharges - this.notCollected - this.totalFees
        },
        
        unsavedUpdates() {
            return this.dailySales.filter(item => (item.creditCardFeesUpdated != item.creditCardFees))            
        }

    },

    mounted() {

        this.user = this.getUser()
        var today = new Date()

        if (today.getDate() == 1) {
            today.setMonth(today.getMonth() - 1)
        }
        var firstDay=new Date(today.getFullYear(), today.getMonth(), 1)
        var lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0)
        this.salesDateRange=[firstDay, lastDay]        

        this.ccVendor = UserStore.getters.getCreditCardVendor
        // this.fetchVendor()
    },

    created: function () {
        window.addEventListener('beforeunload',this.beforeLeavingPage)
    },
  
}
</script>

<style>
/* .scrollable {
    width: 90vw !important;
    padding-right: 10px;
} */
.fullwidth {
    width: 100%;
    
}
.boxheader {
    background-color: rgb(235, 244, 252);
}
.bordertop {
    border-top: 2px solid gray;
}
.borderbottom {
    border-bottom: 2px solid gray;
    border-color: lightgray;
}
.databox {
    background-color:aliceblue;
    margin: 10px;
    padding: 10px;
    box-shadow: 5px 5px #888888;
    vertical-align: top;
}
.topalign {
    vertical-align: top;
}
.box {
    margin: 5px;
}

.fees-input input[type=number]  {

    background-color: white;
    text-align: center;
    font-weight: 900;
    font-size-adjust: calc(500%);
    color: rgb(0, 0, 0);
    width: 100px;
    border-color: gold;
    border-width: 2px;
}

.fees-input input[type=number][disabled] {
    background-color: whitesmoke;
    font-weight:normal;
    color: gray;
    border-color: gray;
    border-width: 1px;
}

.save-buttons-overlay {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 10;
  top: 0;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
</style>